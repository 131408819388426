import React from "react";
import styled from "styled-components";
import * as Theme from "../Theme";
import useDimension from "../hooks/use-dimension";

function PolicyPage(props) {
  const { dimension, rwd } = useDimension();
  const pad = dimension.innerWidth <= Theme.breakpoints.lg;
  const mobile = dimension.innerWidth <= Theme.breakpoints.xs;

  return (
    <Wrapper isPad={pad} isMobile={mobile} rwd={rwd}>
      <div className="content">
        <h1>隱私權政策</h1>
        <p>
          感官文化印刷有限公司（下稱本公司）以最嚴謹的方式保障用戶的隱私權，因此制訂隱私權保護政策。請詳細讀以下有關隱私權保護政策之內容。
        </p>
        <p>
          您並已明確瞭解在您使用感官文化印刷 Sensations Print
          電子商務網站（以下稱本網站）提供的多項服務時，本公司處理您個人資料的政策與原則，
        </p>
        <p>
          以及蒐集利用之範圍及目的。本聲明可能隨時變更，請定期查詢。本公司將依法令及本聲明內容保護您對您個人資料之隱私權。
        </p>
        <p>
          <HighLight>請您細讀以下有關隱私權保護政策的內容：</HighLight>
        </p>
        <h2>一、個人資料的蒐集、處理及利用方式</h2>
        <p>
          當您造訪感官文化印刷或使用本網站所提供之功能服務時，請您提供必要的個人資料，並在該特定目的範圍內處理及利用您的個人資料；
        </p>
        <p>
          非經您書面同意，感官文化印刷不會將個人資料用於其他用途。若您不願提供個人資料或不同意本聲明，請立即停止使用本網服務。
        </p>
        <p>若您繼續使用本網服務，則視為您同意本公司蒐集您的個人資料。</p>
        <h2>
          二、本公司對您所蒐集之個人資料區分為非公開性資料及公開性資料兩種：
        </h2>
        <p>(1) 非公開性資料</p> <p>1-1. 會員註冊：</p>
        <blockquote>
          當您註冊成為本網會員時，本公司會請您提供您的個人資訊，包括姓名、性別、國籍、生日、電子信箱、身分證字號、聯絡住址、電話、帳號、密碼、相片、信用卡卡號、卡片有效日期、信用卡安全碼、通行碼、職業和個人興趣等私密性之資料。這些動作僅為了完成您的訂購手續、隨時查看訂購的情況，為了針對處理您個人訂單與提供更友善的個人化購買服務，因此本公司希望您能夠提供詳細與準確的資料。一經註冊後，您會獲得一組本網站的會員帳號，並且由該帳號充分使用本網站提供的所有會員服務。請您妥善保密您的網路密碼與個人資料，不要將任何個人資料，尤其是網路密碼提供給任何人，以保障您的資料安全。
        </blockquote>
        <p>1-2. 線上活動：</p>
        <blockquote>
          當您參與本網站舉辦的各項線上活動查詢時，我們會請您提供姓名、身份證字號、電話、電子信箱及住址等私密性資料。
        </blockquote>
        <p>(2) 公開性資料：</p> <p>2-1. 一般瀏覽：</p>
        <blockquote>
          為整體評估各個網頁的流量、您進入本網之路徑等資料，我們會保留您在上網瀏覽時，伺服器自行產生的相關記錄，包括您使用連線設備的IP位址、使用時間、使用的瀏覽器、瀏覽及點選資料記錄等。
        </blockquote>
        <p>2-2. 其他：</p>
        <blockquote>
          若您透過任何方式（包含但不限於紙本郵件、電子郵件、電話、傳真或其他通訊軟體）與本公司聯繫，本公司有權保存與您的通訊記錄。
        </blockquote>
        <h2>三、特別注意事項</h2>
        <p>若您所提供之個人資料若有錯誤、不正確情形者，亦不受本聲明之保護。</p>
        <h2>四、資料分享以及公開方式</h2>
        <p>
          本公司除非經由您的同意或基於法律之規定，不會任意揭露、出售、交換或轉讓您的您的個人資料予第三人。
        </p>
        <p>在以下的情況下，本公司會向第三人提供您的個人識別資料：</p>
        <p>(1) 與第三人共用資料前已取得您的同意。</p>
        <p>(2) 需要與第三人共用您的資料，才能夠提供您要求的產品或服務。</p>
        <p>(3) 為遵守法令或因政府機關的要求。</p>
        <p>
          (4)
          本公司發覺您在本網上的行為違反本網服務使用條款或產品、服務的特定使用指南。
        </p>
        <h2>五、資料更新與安全</h2>
        <p>本公司對於個人資料的更新聲明：</p>
        <p>
          您可以在任何時候修改或更新提供予本網之個人帳號資料，包括接受或拒絕本公司通知您特別活動或新產品的決定權。
        </p>
        <p>本公司對於個人資料及線上交易的安全防範聲明：</p>
        <p>
          為保障您的隱私及安全，您在本網的帳號資料會用密碼保護。本公司會盡最大努力確保儲存於系統中的安全性。
        </p>
        <p>
          由於網際網路資料的傳輸不能保證百分之百的安全，儘管本公司努力保護您的個人資料安全，仍無法確保您傳送或接收資料的安全。此風險並不在本公司所承擔的責任範圍內。
        </p>
        <p>在購物過程中，所有資料皆在保密狀態下，故在線上購物是安全無虞的。</p>
        <h2>六、針對本公司蒐集個人資料之告知事項：</h2>
        <p>
          本公司依據個人資料保護法等規定，為下列特定目的之蒐集、處理、利用、國際傳輸當事人之相關個人資料，並尊重當事人之權益，依誠實及信用方法為之，向您告知下列事項：
        </p>
        <ListItem>
          <p>(1) 蒐集之特定目的：</p>
          <p>
            蒐集之目的在於進行Ｏ四Ｏ行銷業務、Ｏ九Ｏ消費者、客戶管理與服務、一四八網路購物及其他電子商務服務及與調查、一五七統計與研究分析、
          </p>
          <p>
            一八一
            其他經營合於營業登記項目或組織章程所定之業務等特定目的及委託他人處理相關事務。
          </p>
        </ListItem>
        <ListItem>
          <p>(2) 個人資料之類別：</p>
          <p>
            本公司蒐集個人資料類別計共有：識別類（C001-C003）、特徵類（C011-C014）、社會情況（C031-C041）。
          </p>
          <p>
            但本公司向您蒐集之個人資料，仍以上開特定目的所實際蒐集之個人資料類別為準。
          </p>
        </ListItem>
        <ListItem>
          <p>(3) 個人資料利用之期間、地區、對象及方式：</p>
          <div style={{ "text-indent": "20px" }}>
            <p> 3-1.期間：</p>
            <p> a.上述個人資料蒐集之特定目的存續期間。</p>
            <p> b.依相關法令規定或契約約定之保存年限（如商業會計法等）。 </p>
            <p> c.本公司因執行業務所必須之保存期間。</p>
            <p> 3-2.對象：</p>
            <p>
              本公司、受本公司委託處理相關事務之第三人、因履行契約所必要之第三人、其他業務相關之第三人、司法主管機關、依法有調查權之機關或其他政府機構、
            </p>
            <p> 其他未受中央目的事業主管機關限制國際傳輸之接收者。</p>
            <p> 3-3.地區：前項對象利用之國內外地區。</p>
            <p> 3-4.方式：以自動化機器或其他非自動化之利用方式</p>
            <p>
              （包括但不限於以言詞、書面、電話、簡訊、電子郵件、傳真、電
              子文件或其他合於當時科學技術之適當方式）。
            </p>
          </div>
        </ListItem>
        <ListItem>
          <p>(4) 您依個人資料保護法第三條規定得行使之權利及方式：</p>
          <div style={{ "text-indent": "24px" }}>
            <p>
              4-1.查詢或請求閱覽或請求製給複製本，本公司依法得酌收必要成本費用；
            </p>
            <p>4-2.請求補充或更正，惟當事人依法應為適當之釋明；</p>
            <p>
              4-3.請求停止蒐集、處理或利用及請求刪除，惟蒐集之特定目的消失前或蒐集期限屆滿前，
            </p>
            <p>本公司依法或因處理業務之必要而須保留者，得不依您的請求為之。</p>
          </div>
        </ListItem>
        <p>
          您如擬行使上述權利，請向本公司提出書面請求(請求補充或更正，6本公司得要求檢附相關釋明文件)，本公司將視情狀於十五日或三十日內，為准駁之決定或查詢之答覆，
        </p>
        <p>
          必要時得予延長決定通知或答覆之期間。請求閱覽者，請於本公司通知之指定期間內，至本公司通知指定之地點閱覽，
        </p>
        <p>
          逾期未至指定地點閱覽而欲再請求閱覽者，需向本公司重新提出書面請求。
        </p>
        <p>
          5.您得自由選擇是否提供全部或一部個人資料，拒絕提供時，本公司將可能無法進行交易及所必要之相關服務。
        </p>
        <p>6.本公司有權修訂本聲明，並得於修訂後公告，不再另行個別通知。</p>
        <p style={{ "margin-top": "40px" }}>
          本公司向您告知上開事項，如您繼續使用本服務，代表您已詳閱、知悉上開告知事項內容及本公司所蒐集、處理及利用您的個人資料之目的及用途。
        </p>
        <p>
          您並依據個人資料保護法第七條第一項及第十九條第五款規定同意提供您的個人資料予本公司為上開特定目的範圍內之蒐集、處理及利用。
        </p>
        <h2>七、 會員就個人資料之權利：</h2>
        <p>
          感官文化印刷所蒐集個人資料之當事人，依個人資料保護法得對感官文化印刷行使以下權利：
        </p>
        <p>(1) 查詢或請求閱覽。</p>
        <p>(2) 請求製給複製本。</p>
        <p>(3) 請求補充或更正。</p>
        <p>(4) 請求停止蒐集、處理或利用。</p>
        <p>
          (5) 請求刪除。
          會員如欲行使上述權利，可與感官文化印刷客服連絡進行申請。
        </p>
        <p>
          請注意！如拒絕提供加入會員所需必要之資料，
          將可能導致無法享受完整服務或完全無法使用該項服務。
        </p>
        <h2>八、Cookie之使用</h2>
        <p>
          為了提供您最佳的服務，感官文化印刷會在您的電腦中放置並取用我們的Cookie，若您不願接受Cookie的寫入，您可在您使用的瀏覽器功能項中設定隱私權等級為高，
        </p>
        <p>即可拒絕Cookie的寫入，但可能會導至網站某些功能無法正常執行 。</p>
        <h2>九、隱私權保護政策之修正</h2>
        <p>
          隨著市場環境的改變本公司將會不時修訂網站政策，感官文化印刷隱私權保護政策將因應需求隨時進行修正，修正後的條款將刊登於網站上。
        </p>
        <p>
          會員如果對於感官文化印刷網站隱私權聲明、或與個人資料有關之相關事項有任何疑問，請與感官文化印刷客服聯絡。
        </p>
        <h2>十、服務聲明</h2>
        <p>
          感官文化印刷 Sensations Print
          電子商務網站，隨著市場環境的改變本公司將會不時修訂網站政策，感官文化印刷保留隨時修改會員服務使用條款，
        </p>
        <p>
          及各該使用規範或約定之權利，修正後的條款將刊登於網站上。
          請會員自行詳加閱讀，不另外個別通知使用者。
        </p>
        <p>
          如果對於感官文化印刷網站隱私權聲明、或與個人資料有關之相關事項有任何疑問，請與感官文化印刷客服聯絡。
        </p>
        <p style={{ "margin-top": "40px" }}>── 感官文化印刷有限公司</p>
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  padding: ${({ isMobile }) => (isMobile ? "20px 0" : "44px 0")};
  & .content {
    margin: 0 auto;
    position: relative;
    outline: none;
    white-space: pre-wrap;
    overflow-wrap: break-word;
    line-height: 1.56;
    max-width: ${({ isMobile, isPad }) => {
      if (isPad || isMobile) return "90%";
      return Theme.centerContentMaxWidth;
    }};
  }
  p {
    margin: 0px;
    font-size: ${({ isMobile }) => (isMobile ? "14px" : "16px")};
  }

  h1 {
    text-align: center;
    font-size: ${({ isMobile }) => (isMobile ? "24px" : "32px")};
    margin-bottom: 40px;
  }

  h2 {
    margin-top: 2em;
    font-size: ${({ isMobile }) => (isMobile ? "16px" : "20px")};

    span {
      /* Add styles for h2 span if needed */
    }
  }

  blockquote {
    border-left: 3px solid lightgray;
    padding-left: 10px;
  }
`;

const HighLight = styled.span`
  background-color: yellow;
`;

const ListItem = styled.div`
  margin: 30px 0;
`;

export default PolicyPage;
